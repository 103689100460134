import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login/login.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { SideMenuComponent } from './layout/side-menu/side-menu.component'
import { FullComponent } from './layout/full.component';
import { FlexLayoutModule } from '@angular/flex-layout';


//Importacions Angular material
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from './shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MosaicMenuComponent } from './layout/mosaic-menu/mosaic-menu.component';
import { MatGridListModule } from '@angular/material/grid-list';

import { InterceptorService } from './core/auth/interceptor.service';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getDutchPaginatorIntl } from './utils/traslate/traslate-paginator';

import { DatePipe } from '@angular/common';
import { OrdenarPipe } from './utils/Pipe/ordenar.pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ResponseInterceptorService } from './core/auth/response-interceptor.service';
import { PinSeguridadComponent } from './layout/pin-seguridad/pin-seguridad.component';
import { CambioPasswordComponent } from './layout/cambio-password/cambio-password.component';
import { PagesComponent } from './public/pages/pages.component';
import { ProfileComponent } from './layout/profile/profile.component';
import { TemplateComponent } from './template/template.component';
import { NuevoSideComponent } from './layout/nuevo-side/nuevo-side.component';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';
import { RecuperacionPasswordComponent } from './public/recuperacion-password/recuperacion-password.component';
import { ValidarConstanciasComponent } from './public/validar-constancias/validar-constancias.component';

// import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SafePipe } from '../app/modules/rrhh/safe.pipe';
import { RecuperarUsuarioComponent } from './public/recuperar-usuario/recuperar-usuario.component';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    SideMenuComponent,
    MosaicMenuComponent,
    OrdenarPipe,
    MosaicMenuComponent,
    PinSeguridadComponent,
    CambioPasswordComponent,
    PagesComponent,
    ProfileComponent,
    TemplateComponent,
    NuevoSideComponent,
    RecuperacionPasswordComponent,
    ValidarConstanciasComponent,
    SafePipe,
    RecuperarUsuarioComponent
    
  ],
  imports: [
    BrowserModule,
    NgxMaskModule.forRoot(),
    MatToolbarModule,
    HttpClientModule,
    MatIconModule,
    MatSidenavModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatExpansionModule,
    MatCardModule,
    MatListModule,
    SharedModule,
    MatMenuModule,
    MatButtonModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    // PdfViewerModule,
    // NgxExtendedPdfViewerModule
    
  ],
  exports: [
    MatProgressSpinnerModule,
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptorService,
      multi: true
    },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
