import { Injectable } from "@angular/core";
import { ToastrServiceLocal } from "../../../services/toast/toastr.service";
import { DataApiService } from "../../../services/data-api.service";
import { tap } from "rxjs/operators";
import { MensajesHttpService } from "../../../utils/mensajesHttp/mensajes-http.service";
import { DataApi } from "src/app/Model/dataApi";
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/internal/operators/catchError";
import { EMPTY } from "rxjs";
import { empleado } from "../../../Model/Empleados/empleados.interface";
import { environment } from "src/environments/environment";
import { ResDatosGenerales } from "../models/resDatosGenerales";

@Injectable({
  providedIn: "root",
})
export class RrhhService {
  public cargando: boolean = false;
  constructor(
    public _toast: ToastrServiceLocal,
    private http: HttpClient,
    public _dataApi: DataApiService,
    public _mensajesHttp: MensajesHttpService
  ) {}

  data: any;
  empleados: any;
  cargarEmpleados() {
    let emp: empleado = this.empleados;
    return (this.data = emp);
  }

  cargarDatos(params: any, callback) {
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/employee/general`;
    const request$ = this._dataApi.GetDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al mostrar los datos generales",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  mostrarEmpleados(params: any, callback) {
    this.cargando = true;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/employee`;
    const request$ = this._dataApi.GetDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
          this.cargando = false;
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
            this.cargando = false;
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al mostrar los empleados",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  mostrarEmpleadoDetalles(params: any, callback) {
    this.cargando = true;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/employee/${params}`;
  
    const request$ = this._dataApi.GetDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
       
         if (!result.hasError) { 
          callback(result);
          this.cargando = false;
         
        } else {
        
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
            this.cargando = false;
          });
          callback();
        } 
    
      }),
      catchError((error: HttpErrorResponse) => {
        this.cargando = false;
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al mostrar los datos generales",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  refreshDeducciones(params: any, callback) {
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/employee/${params}`;
  
    const request$ = this._dataApi.GetDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
       
         if (!result.hasError) { 
          callback(result);
         
        } else {
        
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
          });
          callback();
        } 
    
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al mostrar los datos generales",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  // ACTUALIZAR
  updateEmpleadoDetalles(params: any, callback) {
    console.log(params, "parametro");
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    const request$ = this._dataApi
      .PostDataApi(
        `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/employee`,
        params,
        6
      )
      .pipe(
        tap((result: DataApi | any) => {
          this._toast.clearToasts();
          if (!result.hasError) {
            console.log(result);
            callback(result);
          } else {
            result.errors.forEach((element) => {
              this._toast.mensajeInfo(element.descripcion, "");
            });
            callback();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this._toast.clearToasts();
          this._mensajesHttp.mostrarErrorHttp(
            error,
            "Ocurrio un error al actualizar los detalles del empleado",
            ""
          );
          return EMPTY;
        })
      );
    return request$.subscribe();
  }

  // INSERTAR NUEVO EMPLEADO
  insertarEmpleadoDetalles(params: any, callback) {
    console.log(params, "parametro");
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    const request$ = this._dataApi
      .PostDataApi(
        `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/employee`,
        params,
        6
      )
      .pipe(
        tap((result: DataApi | any) => {
          this._toast.clearToasts();
          if (!result.hasError) {
            callback(result);
          } else {
            result.errors.forEach((element) => {
              this._toast.mensajeInfo(element.descripcion, "");
            });
            callback();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this._toast.clearToasts();
          this._mensajesHttp.mostrarErrorHttp(
            error,
            "Ocurrio un error al ingresar los detalles del empleado",
            ""
          );
          return EMPTY;
        })
      );
    return request$.subscribe();
  }

  //ACTUALIZAR INFO LABORAL
  updateDetallesLaborales(params: any, callback) {
    console.log(params, "parametro");
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    const request$ = this._dataApi
      .PutDataApi(
        `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/employee/working`,
        params,
        6
      )
      .pipe(
        tap((result: DataApi | any) => {
          this._toast.clearToasts();
          if (!result.hasError) {
            callback(result);
          } else {
            result.errors.forEach((element) => {
              this._toast.mensajeInfo(element.descripcion, "");
            });
            callback();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this._toast.clearToasts();
          this._mensajesHttp.mostrarErrorHttp(
            error,
            "Ocurrio un error al actualizar los detalles del empleado",
            ""
          );
          return EMPTY;
        })
      );
    return request$.subscribe();
  }

  //INSERTAR DEDUCCIONES DEL EMPLEADO
  ingresarEmpleadoDeducciones(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    const request$ = this._dataApi
      .PutDataApi(
        `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/detail/deduction`,
        params,
        6
      )
      .pipe(
        tap((result: DataApi | any) => {
          console.log(result);
          this._toast.clearToasts();
          if (!result.hasError) {
            callback(result);
          } else {
            result.errors.forEach((element) => {
              this._toast.mensajeInfo(element.descripcion, "");
            });
            callback();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this._toast.clearToasts();
          this._mensajesHttp.mostrarErrorHttp(
            error,
            "Ocurrio un error al ingresar la deducción",
            ""
          );
          return EMPTY;
        })
      );
    return request$.subscribe();
  }

  updateEmpleadoDeducciones(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    const request$ = this._dataApi
      .PostDataApi(
        `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/detail/deduction`,
        params,
        6
      )
      .pipe(
        tap((result: DataApi | any) => {
          console.log(result);
          this._toast.clearToasts();
          if (!result.hasError) {
            callback(result);
          } else {
            result.errors.forEach((element) => {
              this._toast.mensajeInfo(element.descripcion, "");
            });
            callback();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this._toast.clearToasts();
          this._mensajesHttp.mostrarErrorHttp(
            error,
            "Ocurrio un error al ingresar la deducción",
            ""
          );
          return EMPTY;
        })
      );
    return request$.subscribe();
  }

  // GUARDAR FOTO
  ingresarFotoEmpleado(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    const request$ = this._dataApi
      .PostDataApi(
        `${environment.site}/api/utils/${environment.processEnv}/v1/upload`,
        params,
        4
      )
      .pipe(
        tap((result: DataApi | any) => {
          this._toast.clearToasts();
          if (!result.hasError) {
            callback(result);
          } else {
            result.errors.forEach((element) => {
              this._toast.mensajeInfo(element.descripcion, "");
            });
            callback();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this._toast.clearToasts();
          this._mensajesHttp.mostrarErrorHttp(
            error,
            "Ocurrio un error al ingresar la foto del empleado",
            ""
          );
          return EMPTY;
        })
      );
    return request$.subscribe();
  }

  //GUARDAR PLUS EMPLEADO
  ingresarPlusEmpleado(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    const request$ = this._dataApi
      .PutDataApi(
        `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/detail/plus`,
        params,
        6
      )
      .pipe(
        tap((result: DataApi | any) => {
          this._toast.clearToasts();
          if (!result.hasError) {
            callback(result);
          } else {
            result.errors.forEach((element) => {
              this._toast.mensajeInfo(element.descripcion, "");
            });
            callback();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this._toast.clearToasts();
          this._mensajesHttp.mostrarErrorHttp(
            error,
            "Ocurrio un error al actualizar los detalles del empleado",
            ""
          );
          return EMPTY;
        })
      );
    return request$.subscribe();
  }
  mostrarDeduccionesPlusEmpleado(params: any, callback) {
    console.log(params, "parametro");
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1//employee/pay/plus/${params}`;
    const request$ = this._dataApi.GetDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al mostrar deducciones plus del empleado",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  ingresarDeduccionesPlusEmpleado(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    const request$ = this._dataApi
      .PutDataApi(
        `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/detail/plusDeduction`,
        params,
        6
      )
      .pipe(
        tap((result: DataApi | any) => {
          this._toast.clearToasts();
          if (!result.hasError) {
            callback(result);
          } else {
            result.errors.forEach((element) => {
              this._toast.mensajeInfo(element.descripcion, "");
            });
            callback();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this._toast.clearToasts();
          this._mensajesHttp.mostrarErrorHttp(
            error,
            "Ocurrio un error al ingresar deducciones plus del empleado",
            ""
          );
          return EMPTY;
        })
      );
    return request$.subscribe();
  }

  //VER DEDUCCIONES DE LA PLANILLA PAGADAS
  mostrarDeduccionesPlanilla(params: any, callback) {
    var idPersona = params.idPersona;
    var idPlanilla = params.idPlanilla;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1//employee/pay/${idPlanilla}/${idPersona}`;
    const request$ = this._dataApi.GetDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al ingresar deducciones plus del empleado",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  ingresarPuesto(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    const request$ = this._dataApi
      .PostDataApi(
        `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/detail/employeePosition/`,
        params,
        6
      )
      .pipe(
        tap((result: DataApi | any) => {
          this._toast.clearToasts();
          if (!result.hasError) {
            callback(result);
          } else {
            result.errors.forEach((element) => {
              this._toast.mensajeInfo(element.descripcion, "", 6);
            });
            callback();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this._toast.clearToasts();
          this._mensajesHttp.mostrarErrorHttp(
            error,
            "Ocurrio un error al ingresar el Puesto",
            ""
          );
          return EMPTY;
        })
      );
    return request$.subscribe();
  }

  ingresarVacaciones(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    const request$ = this._dataApi
      .PostDataApi(
        `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/vacations/`,
        params,
        6
      )
      .pipe(
        tap((result: DataApi | any) => {
          this._toast.clearToasts();
          if (!result.hasError) {
            callback(result);
          } else {
            result.errors.forEach((element) => {
              this._toast.mensajeInfo(element.descripcion, "");
            });
            callback();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this._toast.clearToasts();
          this._mensajesHttp.mostrarErrorHttp(
            error,
            "Ocurrio un error al ingresar las vacaciones",
            ""
          );
          return EMPTY;
        })
      );
    return request$.subscribe();
  }

  deleteDeduccionEmpleado(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    var idEmpleado = params.idEmpleado;
    var id = params.id;
    var observaciones = params.observaciones;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/detail/deduction/${id}/${idEmpleado}/${observaciones}`;
    const request$ = this._dataApi.DeleteDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al ingresar las vacaciones",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  deleteDeduccionPlusEmpleado(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    var idEmpleado = params.idEmpleado;
    var id = params.id;
    var idPlus = params.idPlus;
    var observaciones = params.observaciones;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/detail/deductionPlus/${id}/${idPlus}/${idEmpleado}/${observaciones}`;
    const request$ = this._dataApi.DeleteDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al ingresar las vacaciones",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  deletePlusEmpleado(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    var idEmpleado = params.idEmpleado;
    var idPlus = params.idPlus;
    var observaciones = params.observaciones;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/detail/plus/${idPlus}/${idEmpleado}/${observaciones}`;
    const request$ = this._dataApi.DeleteDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al ingresar las vacaciones",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  insertarJornadaEmpleado(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    const request$ = this._dataApi
      .PostDataApi(
        `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/detail/employeeWorkday/`,
        params,
        6
      )
      .pipe(
        tap((result: DataApi | any) => {
          this._toast.clearToasts();
          if (!result.hasError) {
            callback(result);
          } else {
            result.errors.forEach((element) => {
              this._toast.mensajeInfo(element.descripcion, "", 6);
            });
            callback();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this._toast.clearToasts();
          this._mensajesHttp.mostrarErrorHttp(
            error,
            "Ocurrio un error al ingresar los jornada del empleado",
            ""
          );
          return EMPTY;
        })
      );
    return request$.subscribe();
  }

  getTiposConstancias(params: any, callback) {
    this.cargando = true;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/administracion/tipoconstancia/`;
    const request$ = this._dataApi.GetDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
          this.cargando = false;
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
            this.cargando = false;
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al mostrar los empleados",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }


  getConstancia(params: any, callback) {
    this.cargando = true;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/payroll/constancy/${params}/`;
    const request$ = this._dataApi.GetDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
          this.cargando = false;
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
            this.cargando = false;
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al mostrar los empleados",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
  saveKeyConstancia(params: any, callback) {
    this.cargando = true;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/administracion/empleadoconstancia/`;
    const request$ = this._dataApi.PostDataApi(url, params, 6).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
          this.cargando = false;
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
            this.cargando = false;
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  validarConstancia(params: any, callback) {
    this.cargando = true;
    let url = `${environment.site}/api/utils/${environment.processEnv}/v1/repo/valid/go504hnrepo/${params}/60/url/`;
    const request$ = this._dataApi.GetDataApi(url, '', 4).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
          this.cargando = false;
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
            this.cargando = false;
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  saveKeyVoucher(params: any, callback) {
    this.cargando = true;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/administracion/empleadorecibo/`;
    const request$ = this._dataApi.PutDataApi(url, params, 6).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
          this.cargando = false;
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
            this.cargando = false;
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }


  getVistaPreviaCalculo(params: any, callback) {
    this.cargando = true;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/administracion/calculo-detalle/${params}`;
    const request$ = this._dataApi.GetDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
          this.cargando = false;
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
            this.cargando = false;
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al mostrar los empleados",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }


  generaCalculo(params: any, callback) {
    this.cargando = true;
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/administracion/calculo/`;
    const request$ = this._dataApi.PostDataApi(url, params, 6).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
          this.cargando = false;
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
            this.cargando = false;
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  deletePuestoEmpleado(params: any, callback) {
    this._toast.mensajeLoading("Cargando", "Procesando la información");
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/detail/employeePosition/${params}`;
    const request$ = this._dataApi.DeleteDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        if (!result.hasError) {
          callback(result);
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
  mostrarEmpleadoPuesto(params: any, callback) {
    let url = `${environment.site}/api/rrhh/${environment.processEnv}/v1/employee/employee/${params}`;
    const request$ = this._dataApi.GetDataApi(url, "", 6).pipe(
      tap((result: DataApi | any) => {
        if (!result.hasError) {
          callback(result);
          this.cargando = false;
        } else {
          result.errors.forEach((element) => {
            this._toast.mensajeInfo(element.descripcion, "");
            this.cargando = false;
          });
          callback();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.cargando = false;
        this._mensajesHttp.mostrarErrorHttp(
          error,
          "Ocurrio un error al mostrar los datos generales",
          ""
        );
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
}
